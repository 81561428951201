import "./App.css";
import SyllabusPage from "./components/syllabusPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainRoute from "./routes/mainRoute";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainRoute />} />
        <Route path="/topics" element={<SyllabusPage />} />
      </Routes>
    </Router>
  );
}

export default App;
